
    import { defineComponent, onMounted, reactive } from 'vue'
    export default defineComponent({
        setup() {
            // 下层数据
            const dArr = [
                {
                    number: 19016,
                },
            ]
            const numData = reactive([])

            onMounted(() => {
                setNum()
                changeTimes()
            })

            const setNum = () => {
                dArr.forEach(e => {
                    numData.push({
                    config: {
                        number: [e.number],
                        toFixed: 0,
                        content: '{nt}',
                        style:{
                          fontSize: 34,
                          fill: '#fff'
                        }
                    },
                })
            })
            }

            const changeTimes = () => {
                setInterval(() => {
                    changeNum()
                },2000)
            }
            const changeNum = () => {
                numData.forEach((item, index) => {
                    item.config.number[0] += ++index
                item.config = { ...item.config }
            })
            }

            return { numData,}
        }
    })
