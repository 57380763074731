
import {
  defineComponent,
  ref,
  reactive,
  onMounted,
  onBeforeUnmount,
} from 'vue'
import { formatTime } from '@/utils/index'
import { WEEK } from '@/constant/index'
import useIndex from '@/utils/useDraw'
import { title, subtitle, moduleInfo } from '@/constant/index'
import CenterLeft from '../centerLeft/index.vue'
import Center from '../center/index.vue'
import CenterRight from '../centerRight/index.vue'
import BottomLeft from '../bottomLeft/index.vue'
import BottomCenter from '../bottomCenter/index.vue'
import BottomRight from '../bottomRight/index.vue'

export default defineComponent({
  components: {
    CenterLeft,
    Center,
    CenterRight,
    BottomLeft,
    BottomCenter,
    BottomRight
  },
  setup() {
    // * 加载标识
    const loading = ref<boolean>(true)
    // * 时间内容
    const timeInfo = reactive({
      setInterval: 0,
      dateDay: '',
      dateYear: '',
      dateWeek: ''
    })
    // * 适配处理
    const { appRef, calcRate, windowDraw } = useIndex()
    // 生命周期
    onMounted(() => {
      cancelLoading()
      handleTime()
      // todo 屏幕适应
      windowDraw()
      calcRate()

    })

    onBeforeUnmount(() => {
      clearInterval(timeInfo.setInterval)
    })

    // methods
    // todo 处理 loading 展示
    const cancelLoading = () => {
      setTimeout(() => {
        loading.value = false
      }, 500)
    }

    // todo 处理时间监听
    const handleTime = () => {
      timeInfo.setInterval = setInterval(() => {
        const date = new Date()
        timeInfo.dateDay = formatTime(date, 'HH: mm: ss')
        timeInfo.dateYear = formatTime(date, 'yyyy-MM-dd')
        timeInfo.dateWeek = WEEK[date.getDay()]
      }, 1000)
    }

    // return
    return {
      loading,
      timeInfo,
      appRef,
      title,
      subtitle,
      moduleInfo,

    }
  }
})
