
    import { defineComponent, onMounted,} from 'vue'
    import * as echarts from "echarts"

    export default defineComponent({
        setup(){
            const app: any = {};
            //type EChartsOption = echarts.EChartsOption;
            const categories = (function () {
                let now = new Date();
                let res = [];
                let len = 20;
                while (len--) {
                    res.unshift(now.toLocaleTimeString().replace(/^\D*/, ''));
                    now = new Date(+now - 2000);
                }
                return res;
            })();
            const categories2 = (function () {
                let res = [];
                let len = 20;
                while (len--) {
                    res.push(20 - len - 1);
                }
                return res;
            })();
            const data: number[] = (function () {
                let res = [];
                let len = 20;
                while (len--) {
                    res.push(Math.round(Math.random() * 1000));
                }
                return res;
            })();
            const data2: number[] = (function () {
                let res = [];
                let len = 0;
                while (len < 20) {
                    res.push(+(Math.random() * 20 + 10).toFixed(1));
                    len++;
                }
                return res;
            })();
            const init = () => {
                const customChart = echarts.init(document.getElementById('customChart'));
                // 指定图表的配置项和数据

                const option = {
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow',
                            label: {
                                backgroundColor: '#283b56'
                            }
                        }
                    },
                    legend: {
                        textStyle:{
                            color: '#ededed'
                        }
                    },
                    grid:{
                        left: '8%',
                        right: '8%',
                        bottom: '15%',
                        top: '18%'
                    },
                    dataZoom: {
                        show: false,
                        start: 0,
                        end: 100
                    },
                    xAxis: [
                        {
                            type: 'category',
                            boundaryGap: true,
                            data: categories,
                            axisLabel:{
                                color: "#ededed"
                            },
                            splitLine:{
                                show: false
                            }
                        },
                        {
                            show: false,
                            type: 'category',
                            boundaryGap: true,
                            data: categories2,
                            axisLabel:{
                                color: "#ededed"
                            },
                            splitLine:{
                                show: false
                            }
                        }
                    ],
                    yAxis: [
                        {
                            type: 'value',
                            scale: true,
                            name: '数量',
                            min: 0,
                            boundaryGap: [0.2, 0.2],
                            axisLabel:{
                                color: "#ededed"
                            },
                            splitLine:{
                                show: true,
                                lineStyle: {
                                    color: 'rgba(200,200,200,.3)'
                                }
                            }
                        },
                        {
                            type: 'value',
                            scale: true,
                            name: '金额',
                            min: 0,
                            boundaryGap: [0.2, 0.2],
                            axisLabel:{
                                color: "#ededed"
                            },
                            splitLine:{
                                show: false,
                            }
                        }
                    ],
                    series: [
                        {
                            name: '交易额',
                            type: 'bar',
                            barWidth: '40%',
                            showBackground: true,
                            itemStyle: {
                                normal: {
                                    barBorderRadius: 5,
                                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                        { offset: 0, color: "#956FD4" },
                                        { offset: 1, color: "#3EACE5" }
                                    ])
                                }
                            },
                            xAxisIndex: 1,
                            yAxisIndex: 1,
                            data: data
                        },
                        {
                            name: '交易数量',
                            type: 'line',
                            symbolSize: 10,
                            itemStyle: {
                                normal: {
                                    color: "#F02FC2"
                                }
                            },
                            smooth: true,
                            data: data2
                        }
                    ]
                };
                customChart.setOption(option);

                app.count = 11;
                setInterval(function () {
                    let axisData = new Date().toLocaleTimeString().replace(/^\D*/, '');

                    data.shift();
                    data.push(Math.round(Math.random() * 1000));
                    data2.shift();
                    data2.push(+(Math.random() * 20 + 10).toFixed(1));

                    categories.shift();
                    categories.push(axisData);
                    categories2.shift();
                    categories2.push(app.count++);

                    customChart.setOption<echarts.EChartsOption>({
                        xAxis: [
                            {
                                data: categories
                            },
                            {
                                data: categories2
                            }
                        ],
                        series: [
                            {
                                data: data
                            },
                            {
                                data: data2
                            }
                        ]
                    });
                }, 5000);
                window.addEventListener('resize', () => {
                    customChart.resize()
                })
            };

            onMounted(() => {
                init();
            });
            return {init}
        }

    })
