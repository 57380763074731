<template>
  <div class="bottomRight">
    <div style="height:100%;">
      <div class="d-flex pl-2">
        <span>
          <i class="iconfont icon-chart-area" />
        </span>
        <div class="d-flex">
          <span class="fs-xl text mx-2 mt-1">实时交易动态</span>
        </div>
      </div>
      <div class="chart-line">
        <Chart />

      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted,} from 'vue'
import Chart from './chart/index.vue'

export default defineComponent({
    components: {
        Chart
    },

})
</script>

<style lang="scss" class>
$box-width: 100%;
.bottomRight {
  padding: 14px 16px 5px;
  height: 100%;
  width: $box-width;
  border-radius: 5px;
  .text {
    color: #c3cbde;
  }
  .chart-line{height:calc(100% - 24px);}
  .chart-box {
    margin-top: 16px;
    width: 170px;
    height: 170px;
    .active-ring-name {
      padding-top: 10px;
    }
  }
}
</style>
