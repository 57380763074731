
import { defineComponent, onMounted, reactive,ref } from 'vue'


export default defineComponent({
  components:{

  },
  setup() {
    // 下层数据
    let a = ref(672.542);

    let orderNum = reactive([
      '0',
      ',',
      '0',
      '0',
      '0',
      ',',
      '0',
      '0',
      '0',
      '.',
      '0',
      '0',
    ]) // 默认订单总数

    //将数字转成带,的字符串
    const toThousands = (num = 0) => {
      let result = ''
      /*大屏的位置，包括小数点有10个位置
       所以用一个10位的数组补0
       (num + 0.0).toFixed(2) 意思是传入的数字保留两位小数
       */
      let numArr = (Array(10).join('0') + (num + 0.0).toFixed(2))
          .slice(-10)
          .split('.')
      let int = numArr[0]
      //小数位处理，意思是有小数位就用原来的小数位，没有就补.00
      let decmial = numArr[1] ? '.' + numArr[1] : '.00'
      //处理整数位
      for (let n = int.length - 1; n >= 0; n--) {
        result += int[int.length - n - 1]
        if ((int.length - n - 1) % 3 === 0 && n !== 0) {
          result += ','
        }
      }
      return result + decmial
    }

    // 处理总订单数字
    const toOrderNum = (num) => {
      orderNum = toThousands(num).split('')
    }
    const selectNum = (lowNum, upNum) => {
      let num2 = upNum - lowNum + 1
      return Math.floor(Math.random() * num2 + lowNum)
    }
    const increaseNumber = () => {
      setInterval(() => {
        toOrderNum(a.value)
        a.value += 234.56 * (selectNum(60, 90) / 100)
        setNumberTransform()
      }, 5000)
    }
    // 设置文字滚动
    const setNumberTransform = () => {
      const numberItems = document.querySelectorAll('.nItem') // 拿到数字的ref，计算元素数量
      const numberArr = orderNum.filter((item) => !isNaN(Number(item)))
      // 结合CSS 对数字字符进行滚动
      for (let index = 0; index < numberItems.length; index++) {
        const elem = numberItems[index]
        elem['style'].transform = `translate(-50%, -${Number(numberArr[index]) *
        10}%)`
      }
    }

    const dataArr = [
      {
        number: 10798,
        text: '今日总交易笔数',
      },
      // {
      //   number: 45532,
      //   text: '今日总送红包数',
      // },
      {
        number: 5319,
        text: '今日总交易会员数',
      },
      {
        number: 5096,
        text: '今日总交易商户数',
      }
    ]
    // 对应图标
    const iconFont = [
      //'icon-chart-bar',
      'icon-monitoring',
      'icon-cloudupload',
      'icon-laptop'
    ]
    const numberData = reactive([])

    onMounted(() => {
      setData()
      changeTiming()
      increaseNumber()
    })

    const setData = () => {
      dataArr.forEach(e => {
        numberData.push({
          config: {
            number: [e.number],
            toFixed: 0,
            content: '{nt}',
            style:{
              fill: '#9ffff5'
            }
          },
          text: e.text,
        })
      })
    }

    const changeTiming = () => {
      setInterval(() => {
        changeNumber()
      }, 2000)
    }
    const changeNumber = () => {
      numberData.forEach((item, index) => {
        item.config.number[0] += ++index
        item.config = { ...item.config }
      })
    }

    const water = reactive({
      data: [2, 20],
      shape: 'roundRect',
      formatter: '{value}%',
      waveNum: 3
    })

    return { numberData, iconFont,water, orderNum}
  }
})
