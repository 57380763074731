<template>
  <div id="radarChart" style="width: 100%; height: 100%;"></div>
</template>


<script lang="ts">
import { defineComponent, onMounted } from 'vue';
import * as echarts from "echarts"
export default defineComponent({
  setup() {
    const init = () =>{
      const radarChart = echarts.init(document.getElementById('radarChart'));
      const indicatorData = [
        { name: "娱乐休闲", max: 100 },
        { name: "餐饮", max: 100 },
        { name: "便利店", max: 100 },
        { name: "超市", max: 100 },
        { name: "商场", max: 100 },
        { name: "生活服务", max: 100 }
      ]
      const dataHY = [
        [40,70,7,20,12,2],
      ]
      const dataJF = [
        [50,20,1,2,6,0],
      ]
      const dataWX = [
        [60,90,35,60,50,20],
      ]
      const dataZFB = [
        [55,76,15,46,26,16],
      ]
      const dataYM = [
        [46,58,12,21,24,3],
      ]
      const lineStyle = {
        normal: {
          width:2,
          opacity: 1
        }
      }
      const option = {
        tooltip: {
          show: true,
        },
        legend:{
          show: true,
          orient: 'horizontal',
          data:['会员','红包','微信','支付宝','云闪付'],
          bottom: '3%',
          x: 'center',
          itemWidth: 14,
          itemHeight: 7,
          textStyle:{
            fontSize: 11,
            color: '#fff'
          }
        },
        radar: {
          indicator: indicatorData,
          splitNumber: 6,
          radius: ["0%", "50%"],
          center: ['50%','46%'],
          name: {
            textStyle: {
              color: "#ddd"
            }
          },
          nameGap: 5,
          splitLine: {
            lineStyle: {
              color: [
                "rgba(238, 197, 102, 0.4)",
                "rgba(238, 197, 102, 0.5)",
                "rgba(238, 197, 102, 0.6)",
                "rgba(238, 197, 102, 0.7)",
                "rgba(238, 197, 102, 0.8)",
                "rgba(238, 197, 102, 1)"
              ].reverse()
            }
          },
          splitArea: {
            show: false
          },
          axisLine: {
            lineStyle: {
              color: "rgba(238, 197, 102, 1)"
            }
          },
        },
        series: [
          {
            name: "会员",
            type: "radar",
            lineStyle: lineStyle,
            data: dataHY,
            symbol: "circle",
            symbolSize: 2,
            itemStyle: {
              normal: {
                color: "#41da8a"
              }
            },
            areaStyle: {
              normal: {
                opacity: 0.6
              }
            }
          },
          {
            name: "红包",
            type: "radar",
            lineStyle: lineStyle,
            data: dataJF,
            symbol: "circle",
            symbolSize: 2,
            itemStyle: {
              normal: {
                color: "#9fe6b8"
              }
            },
            areaStyle: {
              normal: {
                opacity: 0.6
              }
            }
          },
          {
            name: "微信",
            type: "radar",
            lineStyle: lineStyle,
            data: dataWX,
            symbol: "circle",
            symbolSize: 2,
            itemStyle: {
              normal: {
                color: "#2497ea"
              }
            },
            areaStyle: {
              normal: {
                opacity: 0.6
              }
            }
          }
          ,{
            name: "支付宝",
            type: "radar",
            lineStyle: lineStyle,
            data: dataZFB,
            symbol: "circle",
            symbolSize: 2,
            itemStyle: {
              normal: {
                color: "#e47e5d"
              }
            },
            areaStyle: {
              normal: {
                opacity: 0.6
              }
            }
          },
          {
            name: "云闪付",
            type: "radar",
            lineStyle: lineStyle,
            data: dataYM,
            symbol: "circle",
            symbolSize: 2,
            itemStyle: {
              normal: {
                color: "#bc35e4"
              }
            },
            areaStyle: {
              normal: {
                opacity: 0.6
              }
            }
          }//end
        ]
      }
      radarChart.setOption(option);
      window.addEventListener('resize', () => {
        radarChart.resize()
      })
    }
    onMounted(() => {
      init();
    });
    return {init}
  },
});
</script>


<style lang="scss" scoped>

</style>
