<template>
  <div class="sj-statistic d-flex jc-between ai-center">
    <div class=" item-box flex-1 d-flex ai-center" v-for="(item, index) in numData" :key="index">
        <span>实时商户个数：</span>
        <dv-digital-flop class="dv-digital-flop" :config="item.config" />
    </div>
  </div>
</template>


<script lang="ts">
    import { defineComponent, onMounted, reactive } from 'vue'
    export default defineComponent({
        setup() {
            // 下层数据
            const dArr = [
                {
                    number: 19016,
                },
            ]
            const numData = reactive([])

            onMounted(() => {
                setNum()
                changeTimes()
            })

            const setNum = () => {
                dArr.forEach(e => {
                    numData.push({
                    config: {
                        number: [e.number],
                        toFixed: 0,
                        content: '{nt}',
                        style:{
                          fontSize: 34,
                          fill: '#fff'
                        }
                    },
                })
            })
            }

            const changeTimes = () => {
                setInterval(() => {
                    changeNum()
                },2000)
            }
            const changeNum = () => {
                numData.forEach((item, index) => {
                    item.config.number[0] += ++index
                item.config = { ...item.config }
            })
            }

            return { numData,}
        }
    })
</script>


<style lang="scss">
    .item-box {flex: 1;position: relative; font-weight: bold; text-shadow: 0 2px 3px rgba(0,0,0,.6); color: #fff; border-radius: 4px; white-space: nowrap; padding-right: 20px;
        & > div {padding-right: 5px;}
        .dv-digital-flop {width: 140px;height: 34px; margin-top: 7px;
            canvas{fill: #000!important;}
        }
        p {text-align: center; display: inline-block;line-height: 30px; vertical-align: top; font-weight: bold; }
    }
</style>
