<template>
  <div class="chartNum">
    <div class="box-item">
      <li
        v-for="(item, index) in orderNum"
        :class="{
          'number-item': !isNaN(Number(item)),
          'mark-item': isNaN(Number(item)),
        }"
        :key="index"
      >
        <span v-if="!isNaN(Number(item))">
          <i ref="numberItem" class="numberItem">0123456789</i>
        </span>
        <span class="comma" v-else>{{ item }}</span>
      </li>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, reactive, onMounted } from 'vue'
export default defineComponent({
  setup() {
    onMounted(() => {
      increaseNumber()
    })
    let a = ref(1034.678)
    let orderNum = reactive([
      '0',
      ',',
      '0',
      '0',
      '1',
      ',',
      '0',
      '3',
      '4',
      '.',
      '6',
      '8',
    ]) // 默认订单总数

    //将数字转成带,的字符串
    const toThousands = (num = 0) => {
      let result = ''
      /*大屏的位置，包括小数点有10个位置
        所以用一个10位的数组补0
        (num + 0.0).toFixed(2) 意思是传入的数字保留两位小数
      */
      let numArr = (Array(10).join('0') + (num + 0.0).toFixed(2))
        .slice(-10)
        .split('.')
      let int = numArr[0]
      //小数位处理，意思是有小数位就用原来的小数位，没有就补.00
      let decmial = numArr[1] ? '.' + numArr[1] : '.00'
      //处理整数位
      for (let n = int.length - 1; n >= 0; n--) {
        result += int[int.length - n - 1]
        if ((int.length - n - 1) % 3 === 0 && n !== 0) {
          result += ','
        }
      }
      return result + decmial
    }

    // 处理总订单数字
    const toOrderNum = (num) => {
      orderNum = toThousands(num).split('')
    }

    const increaseNumber = () => {
      setInterval(() => {
        toOrderNum(a.value)
        a.value += 5234.56
        if(a.value > 99999999.99){
            a.value = 99999999.99
        }
        setNumberTransform()
      },5000)
    }
    // 设置文字滚动
    const setNumberTransform = () => {
      const numberItems = document.querySelectorAll('.numberItem') // 拿到数字的ref，计算元素数量
      const numberArr = orderNum.filter((item) => !isNaN(Number(item)))
      // 结合CSS 对数字字符进行滚动
      for (let index = 0; index < numberItems.length; index++) {
        const elem = numberItems[index]
        elem['style'].transform = `translate(-50%, -${Number(numberArr[index]) *
          10}%)`
      }
    }
    // return
    return {
      orderNum,
    }
  },
})
</script>

<style lang="scss" scoped>
@font-face {
  font-family: 'AGENCYB';
  src: url('../../../assets/fonts/AGENCYB.TTF') format('truetype');
  font-style: normal;
  font-weight: 600;
}
/*滚动数字设置*/
.chartNum {
  height: calc(100% - 30px);
  overflow: hidden;
  width: 100%;
}
.box-item {
  position: relative;
  height: 90%;
  width: 80%;
  margin: 0 auto;
  font-size: 4.6em;
  text-align: center;
  list-style: none;
  color: #ffffff;
  writing-mode: vertical-lr;
  text-orientation: upright;
  /*文字禁止编辑*/
  -moz-user-select: none; /*火狐*/
  -webkit-user-select: none; /*webkit浏览器*/
  -ms-user-select: none; /*IE10*/
  -khtml-user-select: none; /*早期浏览器*/
  user-select: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 30px;
  &:before {
    content: '¥';
    color: #fff;
    font-size: 0.5em;
    position: absolute;
    left: 0;
    top: 64%;
  }
}
/* 默认逗号设置 */
.mark-item {
  width: 20px;
  height: 80%;
  margin-right: 5px;
  line-height: 18px;
  font-size: 1em;
  position: relative;
  & > span {
    position: absolute;
    width: 100%;
    bottom: 0;
    writing-mode: vertical-rl;
    text-orientation: upright;
  }
}
/*滚动数字设置*/
.number-item {
  flex: 1;
  height: 80%;
  position: relative;
  background: linear-gradient(to bottom, #3b959e, #1e4259);
  list-style: none;
  margin-right: 5px;
  border-radius: 4px;
  max-width: 60px;
  min-width: 20px;
  //border:1px solid rgba(221,221,221,1);
  & > span {
    position: relative;
    display: inline-block;
    margin-right: 10px;
    width: 100%;
    height: 100%;
    writing-mode: vertical-rl;
    text-orientation: upright;
    overflow: hidden;
    z-index: 3;
    & > i {
      font-style: normal;
      font-family: AGENCYB;
      position: absolute;
      top: 10px;
      left: 50%;
      transform: translate(-50%, 0);
      transition: transform 1s ease-in-out;
      letter-spacing: 10px;
    }
  }
  &:before {
    content: '';
    width: 80%;
    height: 1px;
    background: #285a83;
    position: absolute;
    top: 50%;
    left: 10%;
  }
}
.number-item:last-child {
  margin-right: 0;
}
@media (max-width: 1366px) {
  .box-item {
    width: 100%;
    font-size: 3.6em;
  }
}
@media (min-width: 1367px) and (max-width: 1600px) {
  .box-item {
    font-size: 4.1em;
  }
}
@media (min-height: 800px) and (max-height: 860px) {
  .box-item {
    font-size: 4.1em;
  }
}
@media (max-height: 800px) {
  .box-item {
    font-size: 3.6em;
  }
}
@media (min-height: 640px) and (max-height: 800px) {
  .box-item {
    font-size: 2.6em;
  }
}
@media (max-height: 640px) {
  .box-item {
    font-size: 2.4em;
    &:before {
      top: 60%;
    }
  }
  .number-item {
    span {
      i {
        top: 5px;
      }
    }
  }
}
</style>
