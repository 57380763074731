<template>
  <div class="centreLeft1">
    <div class="num-part">
      <div class="d-flex pt-2 pl-2">
        <span>
          <icon class="iconfont icon-layer-group"></icon>
        </span>
        <div class="d-flex">
          <span class="fs-xl text mx-2">实时总交易额</span>
          <dv-decoration-1 class="dv-dec-1" />
        </div>
      </div>
      <Number :numm="856631" :numLen="9"></Number>
      
    </div>
    <div class="chart-part clearfix">
      <div class="map-chart">
        <chart />
      </div>
      <div class="sort-item">
        <span>
          <i class="iconfont icon-tongji2"></i>  总交易额TOP5城市
        </span>
        <dv-scroll-ranking-board class="dv-scr-rank-board" :config="ranking" />
      </div>
    </div>
  </div>
</template>

<script lang='ts'>
import { defineComponent,reactive,onMounted } from 'vue'
import Chart from './chart/index.vue'
import Number from './number/index2.vue'
export default defineComponent({
  components: {
    Chart, Number
  },
    setup() {

      const ranking = reactive({
        data: [
            {
                name: '广州',
                value: 535
            },
            {
                name: '上海',
                value: 283
            },
            {
                name: '云南',
                value: 219
            },
            {
                name: '上海',
                value: 85
            },
            {
                name: '长沙',
                value: 70
            },
        ],
        carousel: 'single',
        unit: '万'
      })
        return {
            ranking,
        }
    }
})
</script>

<style lang="scss">
.centreLeft1 {
  $box-width: 300px;
  $box-height: 520px;
  padding: 16px 16px 0 16px;
  height: 100%;
  min-width: $box-width;
  min-height: $box-height;
  border-radius: 5px;
  position: relative;
  .num-part{width: 100%; height: 35%; z-index: 5;}
  .chart-part{height:72%; position: relative; z-index: 3; margin-top: -3%;
    .map-chart{width:65%; height: 100%; float: left;}
    .sort-item{ height: 100%; width: 32%; float: left; display: flex; flex-direction: column; justify-content: flex-end;
      &> span{font-size: 0.8em;}
      .dv-scroll-ranking-board{height: 220px; width: 100%; margin-bottom: 20px;
        .ranking-info{
          .rank{color: #9a9691!important;}
        }
        .ranking-column{border-bottom: 1px solid rgba(228,239,255,.5)!important;
          .inside-column{background-color: #dc7a1c !important;}
        }
        .shine {
          background: radial-gradient(rgb(255, 238, 215) 5%, transparent 80%)!important;
        }
      }
    }
  }

  .text {
    color: #c3cbde;
  }
  .dv-dec-1 {
    position: relative;
    width: 100px;
    height: 20px;
    top: -3px;
  }
  .chart-box {
    margin-top: 16px;
    width: 170px;
    height: 170px;
    .active-ring-name {
      padding-top: 10px;
    }
  }
}
@media (max-height: 660px){
  .centreLeft1{
    .chart-part{
      .sort-item{
        .dv-scroll-ranking-board{height: 150px;
          .ranking-info{font-size: 0.75em}
          .ranking-column{margin-top: 0;}
        }
      }
    }
  }

}
</style>
