
import { defineComponent,reactive,onMounted } from 'vue'
import Chart from './chart/index.vue'
import Number from './number/index2.vue'
export default defineComponent({
  components: {
    Chart, Number
  },
    setup() {

      const ranking = reactive({
        data: [
            {
                name: '广州',
                value: 535
            },
            {
                name: '上海',
                value: 283
            },
            {
                name: '云南',
                value: 219
            },
            {
                name: '上海',
                value: 85
            },
            {
                name: '长沙',
                value: 70
            },
        ],
        carousel: 'single',
        unit: '万'
      })
        return {
            ranking,
        }
    }
})
