<template>
  <div class="centerRight2">
    <div class="d-flex pt-2 pl-2">
      <span>
        <i class="iconfont icon-vector" />
      </span>
      <div class="d-flex">
        <span class="fs-xl text mx-2">商户交易实时播报</span>
        <dv-decoration-3 class="dv-dec-3" />
      </div>
    </div>
    <div class="d-flex mt-1 jc-center body-box">
      <Number />
      <dv-scroll-board class="dv-scr-board" :config="config" />
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive, onMounted} from 'vue'
import Number from './number/index.vue'
export default defineComponent({
    components: {
        Number
    },
  setup() {
    const config = reactive({
      header: ['名称', '金额(元)','付钱方式'],
      data: [
        ["老庙黄金", "<strong style='color:#ffc000;'>658</strong>", '支付宝'],
        ["我家鹅小姐", "<strong style='color:#FFC000;'>109</strong>", '支付宝'],
        ["苏州鑫吴蟹业", "<strong style='color:#FFC000;'>1800</strong>", '微信'],
        ["锦枫智美酒店", "<strong style='color:#FFC000;'> 2735</strong>", '支付宝'],
        ["左庭右院", "<strong style='color:#FFC000;'>4180</strong>", '微信'],
        ["九思圣德文化", "<strong style='color:#FFC000;'>1980</strong>", '支付宝'],
        ["爱美阁", "<strong style='color:#FFC000;'>1879</strong>", '微信'],
        ["耳博匠", "<strong style='color:#FFC000;'>70</strong>", '微信'],
        ["童林电子商务有限公司", "<strong style='color:#ffc000;'>589</strong>", '支付宝'],
        ["祥符区八里湾镇志辉副食超市", "<strong style='color:#FFC000;'>1109</strong>", '支付宝'],
        ["杨记平价超市", "<strong style='color:#FFC000;'>312</strong>", '微信'],
        ["沾化区龙霆电器城", "<strong style='color:#FFC000;'> 7635</strong>", '支付宝'],
        ["新合作超市连锁有限公司", "<strong style='color:#FFC000;'>4280</strong>", '微信'],
        ["邦士洁购物中心", "<strong style='color:#FFC000;'>286</strong>", '支付宝'],
        ["汽车真皮座椅私人订制", "<strong style='color:#FFC000;'>979</strong>", '微信'],
        ["乐众商贸有限公司", "<strong style='color:#FFC000;'>507</strong>", '微信'],
        ["姿焕堂瘦身养", "<strong style='color:#FFC000;'>409</strong>", '支付宝'],
        ["苏萍汽车服务中心", "<strong style='color:#FFC000;'>982</strong>", '微信'],
        ["升发贸易有限责任公司", "<strong style='color:#FFC000;'> 635</strong>", '支付宝'],
        ["恺晅礼品", "<strong style='color:#FFC000;'>280</strong>", '微信'],
        ["邦士洁购物中心", "<strong style='color:#FFC000;'>280</strong>", '支付宝'],
        ["众祥电子科技有限公司", "<strong style='color:#FFC000;'>2699</strong>", '微信'],
        ["优漾美学", "<strong style='color:#FFC000;'>70</strong>", '微信'],
      ],
      rowNum: 8, //表格行数
      headerHeight: 34,
      headerBGC: '#393f58', //表头
      oddRowBGC: '#0f1325', //奇数行
      evenRowBGC: '#171c33', //偶数行
      index: false,
      //columnWidth: [50],
      align: ['left','right','center']
    })

    return { config }
  }
})
</script>

<style lang="scss">
$box-height: 100%;
.centerRight2 {
  padding: 16px;
  padding-top: 20px;
  height: $box-height;
  width: 100%;
  //width: $box-width;
  border-radius: 5px;
  .text {
    color: #c3cbde;
  }
  .dv-dec-3 {
    position: relative;
    width: 100px;
    height: 20px;
    top: -3px;
  }
  .body-box {border-radius: 10px;overflow: hidden;display: flex; flex-direction: column; height: calc(100% - 30px);
    .dv-scroll-board {height: 78%;
      .text {
        padding: 0 6px;
      }
      .header {
        font-size: 0.8em;
      }
      .rows {
        .row-item {
          font-size: 0.75em;
        }
      }
    }
    .sj-statistic{flex:1; display: flex; align-items: center; justify-content: left; padding: 0 30px; margin: 10px 0; background: #005fd8; border-radius: 5px; white-space: nowrap;
      span{color: #efefef; margin-right: 20px;
        i{font-size: 1.2em; margin-right: 20px;}
      }
      strong{font-size: 2.2em; color:#fefaff;}
    }
  }
}
</style>
