<template>
  <div class="bottomLeft">
    <div style="height:100%;">
      <div class="d-flex pl-2">
        <span>
          <i class="iconfont icon-chart-line" />
        </span>
        <div class="d-flex">
          <span class="fs-xl text mx-2 mt-1">支付通道占比</span>
        </div>
      </div>
      <div class="chart-bar">
        <ChartPie />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import ChartPie from "./chartPie/index.vue";
export default defineComponent({
  components: {
    ChartPie
  }
})
</script>

<style lang="scss" scoped>
$box-width: 100%;
.bottomLeft {
  padding: 16px 16px 5px 16px;
  height: 100%;
  width: $box-width;
  border-radius: 5px;
  .text {
    color: #c3cbde;
  }
  .chart-bar{width: 100%; height: calc(100% - 24px);}
  .chart-box {
    margin-top: 16px;
    width: 170px;
    height: 100%;
    .active-ring-name {
      padding-top: 10px;
    }
  }
}
</style>
