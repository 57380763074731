
import { defineComponent, onMounted,} from 'vue'
import Chart from './chart/index.vue'

export default defineComponent({
    components: {
        Chart
    },

})
