<template>
  <div class="centreLeft1">
    <div class="bg-color-black">
      <div class="d-flex pt-2 pl-2">
        <span>
          <i class="iconfont icon-tongji4" />
        </span>
        <div class="d-flex">
          <span class="fs-xl text mx-2">华商智联进行时</span>
          <dv-decoration-3 class="dv-dec-3" />
        </div>
      </div>
      <!-- 4个主要的数据 -->
      <div class="bottom-data d-flex flex-column">
        <div class="item-box mt-2 d-flex jc-between ai-center" style="height: 18%;">
          <div>
            <i class="iconfont icon-chart-bar"></i>
            <p>
              <span>今日总使用红包数</span>
            </p>
          </div>
          <div style="flex:1; height:100%; display: flex; align-items: center;">
            <div class="tNum">
              <div class="b-item">
                <li
                    v-for="(item, index) in orderNum"
                    :class="{
                        'n-item': !isNaN(Number(item)),
                        'm-item': isNaN(Number(item)),
                      }"
                    :key="index"
                >
                  <span v-if="!isNaN(Number(item))">
                    <i ref="numberItem" class="nItem">0123456789</i>
                  </span>
                  <span class="com" v-else>{{ item }}</span>
                </li>
              </div>
            </div>
          </div>
        </div>
        <div class="item-box mt-2 d-flex jc-between ai-center" v-for="(item, index) in numberData" :key="index">
          <div class="">
            <i class="iconfont" :class="[iconFont[index]]" />
            <p>
              <span> {{ item.text }} </span>
            </p>
          </div>
          <dv-digital-flop class="dv-digital-flop" :config="item.config" />
        </div>
        <div class="data-chart">
          <dv-water-level-pond class="dv-wa-le-po" :config="water" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive,ref } from 'vue'


export default defineComponent({
  components:{

  },
  setup() {
    // 下层数据
    let a = ref(672.542);

    let orderNum = reactive([
      '0',
      ',',
      '0',
      '0',
      '0',
      ',',
      '0',
      '0',
      '0',
      '.',
      '0',
      '0',
    ]) // 默认订单总数

    //将数字转成带,的字符串
    const toThousands = (num = 0) => {
      let result = ''
      /*大屏的位置，包括小数点有10个位置
       所以用一个10位的数组补0
       (num + 0.0).toFixed(2) 意思是传入的数字保留两位小数
       */
      let numArr = (Array(10).join('0') + (num + 0.0).toFixed(2))
          .slice(-10)
          .split('.')
      let int = numArr[0]
      //小数位处理，意思是有小数位就用原来的小数位，没有就补.00
      let decmial = numArr[1] ? '.' + numArr[1] : '.00'
      //处理整数位
      for (let n = int.length - 1; n >= 0; n--) {
        result += int[int.length - n - 1]
        if ((int.length - n - 1) % 3 === 0 && n !== 0) {
          result += ','
        }
      }
      return result + decmial
    }

    // 处理总订单数字
    const toOrderNum = (num) => {
      orderNum = toThousands(num).split('')
    }
    const selectNum = (lowNum, upNum) => {
      let num2 = upNum - lowNum + 1
      return Math.floor(Math.random() * num2 + lowNum)
    }
    const increaseNumber = () => {
      setInterval(() => {
        toOrderNum(a.value)
        a.value += 234.56 * (selectNum(60, 90) / 100)
        setNumberTransform()
      }, 5000)
    }
    // 设置文字滚动
    const setNumberTransform = () => {
      const numberItems = document.querySelectorAll('.nItem') // 拿到数字的ref，计算元素数量
      const numberArr = orderNum.filter((item) => !isNaN(Number(item)))
      // 结合CSS 对数字字符进行滚动
      for (let index = 0; index < numberItems.length; index++) {
        const elem = numberItems[index]
        elem['style'].transform = `translate(-50%, -${Number(numberArr[index]) *
        10}%)`
      }
    }

    const dataArr = [
      {
        number: 10798,
        text: '今日总交易笔数',
      },
      // {
      //   number: 45532,
      //   text: '今日总送红包数',
      // },
      {
        number: 5319,
        text: '今日总交易会员数',
      },
      {
        number: 5096,
        text: '今日总交易商户数',
      }
    ]
    // 对应图标
    const iconFont = [
      //'icon-chart-bar',
      'icon-monitoring',
      'icon-cloudupload',
      'icon-laptop'
    ]
    const numberData = reactive([])

    onMounted(() => {
      setData()
      changeTiming()
      increaseNumber()
    })

    const setData = () => {
      dataArr.forEach(e => {
        numberData.push({
          config: {
            number: [e.number],
            toFixed: 0,
            content: '{nt}',
            style:{
              fill: '#9ffff5'
            }
          },
          text: e.text,
        })
      })
    }

    const changeTiming = () => {
      setInterval(() => {
        changeNumber()
      }, 2000)
    }
    const changeNumber = () => {
      numberData.forEach((item, index) => {
        item.config.number[0] += ++index
        item.config = { ...item.config }
      })
    }

    const water = reactive({
      data: [2, 20],
      shape: 'roundRect',
      formatter: '{value}%',
      waveNum: 3
    })

    return { numberData, iconFont,water, orderNum}
  }
})
</script>

<style lang="scss">
  .centreLeft1 {
    padding: 16px;
    height: 100%;
    min-height: 100%!important;
    border-radius: 10px;
    .bg-color-black {
      height: calc(100% - 20px);
      border-radius: 10px;
    }
    .text {
      color: #c3cbde;
    }
    .dv-dec-3 {
      position: relative;
      width: 100px;
      height: 20px;
      top: -3px;
    }
    .bottom-data {height: calc(100% - 25px); display: flex; flex-direction: column;
      .item-box {flex: 1;font-size: 0.9em;position: relative;color: #fff; text-shadow: 0 2px 3px rgba(0,0,0,.6); border-radius: 4px; white-space: nowrap; padding-right: 20px;
        & > div {padding-right: 5px;}
        .dv-digital-flop {width: 140px;height: 30px; margin-top: 7px;
          canvas{fill: #000!important;}
        }
        i{line-height: 30px;margin: 0 10px; color: #fff; font-weight: normal;}
        p {text-align: center; display: inline-block;line-height: 30px; vertical-align: top; font-weight: bold; }
        &:nth-of-type(1){background: linear-gradient(to right, #37a2da, rgba(0,0,0,0));}
        &:nth-of-type(2){background: linear-gradient(to right, #32c5e9, rgba(0,0,0,0));}
        &:nth-of-type(3){background: linear-gradient(to right, #67e0e3, rgba(0,0,0,0));}
        &:nth-of-type(4){background: linear-gradient(to right, #9fe6b8, rgba(0,0,0,0));}
      }
      .data-chart{height: 70px; padding: 20px 0 0 0; opacity: .8;
        .dv-water-pond-level {height: 100%;
          rect{stroke-width: 0; width:100%!important;}
          text{display: none!important;}
          canvas {width:100%!important;height: 100%!important;margin: 0!important;}
        }
      }
    }
  }

  .tNum {
    height: 30px;
    overflow: hidden;
    width: 100%;
    padding-right: 5px;
    display: flex;
    justify-content: flex-end;
  }
  .b-item {
    position: relative;
    height: 100%;
    width: 70%;
    font-size: 30px;
    text-align: center;
    list-style: none;
    color: #9ffff5;
    writing-mode: vertical-lr;
    text-orientation: upright;
    /*文字禁止编辑*/
    -moz-user-select: none; /*火狐*/
    -webkit-user-select: none; /*webkit浏览器*/
    -ms-user-select: none; /*IE10*/
    -khtml-user-select: none; /*早期浏览器*/
    user-select: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &:before {
     content: '¥';
     color: #9ffff5;
     font-size: 14px;
     position: absolute;
     left: -17px;
     top: 43%;
   }
  }
  /* 默认逗号设置 */
  .m-item {
    width: 5px;
    height: 100%;
    margin:0;
    font-size: 20px;
    position: relative;
    & > span {
      position: absolute;
      width: 100%;
      bottom: 0;
      left: 6px;
      writing-mode: vertical-rl;
      text-orientation: upright;
    }
  }
  /*滚动数字设置*/
  .n-item {
    flex: 1;
    height:100%;
    position: relative;
    list-style: none;
    border-radius: 4px;
    max-width: 30px;
    min-width: 10px;
    & > span {
      position: relative;
      display: inline-block;
      width: 100%;
      height: 100%;
      writing-mode: vertical-rl;
      text-orientation: upright;
      overflow: hidden;
      z-index: 3;
      & > i {
        font-style: normal;
        position: absolute;
        top: 0;
        left: 50%;
        color: #9ffff5!important;
        line-height: 30px!important;
        margin: 0!important;
        transform: translate(-50%, 0);
        transition: transform 1s ease-in-out;
      }
    }
  }


</style>
