
import { defineComponent, ref, reactive, onMounted } from 'vue'
export default defineComponent({
  setup() {
    onMounted(() => {
      increaseNumber()
    })
    let a = ref(1034.678)
    let orderNum = reactive([
      '0',
      ',',
      '0',
      '0',
      '1',
      ',',
      '0',
      '3',
      '4',
      '.',
      '6',
      '8',
    ]) // 默认订单总数

    //将数字转成带,的字符串
    const toThousands = (num = 0) => {
      let result = ''
      /*大屏的位置，包括小数点有10个位置
        所以用一个10位的数组补0
        (num + 0.0).toFixed(2) 意思是传入的数字保留两位小数
      */
      let numArr = (Array(10).join('0') + (num + 0.0).toFixed(2))
        .slice(-10)
        .split('.')
      let int = numArr[0]
      //小数位处理，意思是有小数位就用原来的小数位，没有就补.00
      let decmial = numArr[1] ? '.' + numArr[1] : '.00'
      //处理整数位
      for (let n = int.length - 1; n >= 0; n--) {
        result += int[int.length - n - 1]
        if ((int.length - n - 1) % 3 === 0 && n !== 0) {
          result += ','
        }
      }
      return result + decmial
    }

    // 处理总订单数字
    const toOrderNum = (num) => {
      orderNum = toThousands(num).split('')
    }

    const increaseNumber = () => {
      setInterval(() => {
        toOrderNum(a.value)
        a.value += 5234.56
        if(a.value > 99999999.99){
            a.value = 99999999.99
        }
        setNumberTransform()
      },5000)
    }
    // 设置文字滚动
    const setNumberTransform = () => {
      const numberItems = document.querySelectorAll('.numberItem') // 拿到数字的ref，计算元素数量
      const numberArr = orderNum.filter((item) => !isNaN(Number(item)))
      // 结合CSS 对数字字符进行滚动
      for (let index = 0; index < numberItems.length; index++) {
        const elem = numberItems[index]
        elem['style'].transform = `translate(-50%, -${Number(numberArr[index]) *
          10}%)`
      }
    }
    // return
    return {
      orderNum,
    }
  },
})
